import { errorRequest, urlConstant } from '../Constants';
import { groupErrorRequests } from '../Handlers';
import Maintenance from '../Modules/maintenance.module';
import moment from 'moment/moment';
import { userService } from './index';
import {AxiosRequest} from "./AxiosApi";

const isRepair = Maintenance.getMaintenance();

export const reportsService = {
    getReportsFilters,
    getReports,
    getSubReports,
    getSubSubReports,
    getReportsDetails,
};

function getReportsFilters() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/reports`,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('get reports filters', error);

                if (errorMessage === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken(() => getReportsFilters());
                }

                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getReports(page, size, from, to, users, groups, templates, filters, showEmptyValue, showTestData) {

    if (users[0] === 'default') {
        users = [];
    }
    if (groups[0] === 'default') {
        groups = [];
    }
    if (templates[0] === 'default') {
        templates = [];
    }
    let first = '';
    let second = '';
    let third = '';

    function getItemName(filters) {
        first = filters[0].ident;
        second = filters[1].ident;
        third = filters[2].ident;
    }

    getItemName(filters);

    function getItemValue(users, groups, templates, ident) {
        if (ident === 'user_id') {
            return users;
        } else if (ident === 'group_id') {
            return groups;
        } else if (ident === 'template_id') {
            return templates;
        }
    }

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/reports/first?page=${page}&size=${size}`,
            data: {
                dateFrom: moment(from, 'DD/MM/YYYY').unix(),
                dateTo: moment(to, 'DD/MM/YYYY').unix(),
                group1: {
                    name: first,
                    filters: getItemValue(users, groups, templates, first),
                },
                group2: {
                    name: second,
                    filters: getItemValue(users, groups, templates, second),
                },
                group3: {
                    name: third,
                    filters: getItemValue(users, groups, templates, third),
                },
                showEmptyValue: showEmptyValue,
                showTestData
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('get reports', error);
                if (errorMessage === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken(() =>
                        getReports(page, size, from, to, users, groups, templates, filters, showEmptyValue),
                    );
                }
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getSubReports(page, size, from, to, users, groups, templates, filters, itemId, showEmptyValue, showTestData) {

    if (users[0] === 'default') {
        users = [];
    }
    if (groups[0] === 'default') {
        groups = [];
    }
    if (templates[0] === 'default') {
        templates = [];
    }
    let first = '';
    let second = '';
    let third = '';

    function getItemName(filters) {
        first = filters[0].ident;
        second = filters[1].ident;
        third = filters[2].ident;
    }

    getItemName(filters);

    function getItemValue(users, groups, templates, ident) {
        if (ident === 'user_id') {
            return users;
        } else if (ident === 'group_id') {
            return groups;
        } else if (ident === 'template_id') {
            return templates;
        }
    }

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/reports/second`,
            data: {
                dateFrom: moment(from, 'DD/MM/YYYY').unix(),
                dateTo: moment(to, 'DD/MM/YYYY').unix(),
                group1: {
                    name: first,
                    filters: [itemId],
                },
                group2: {
                    name: second,
                    filters: getItemValue(users, groups, templates, second),
                },
                group3: {
                    name: third,
                    filters: getItemValue(users, groups, templates, third),
                },
                showEmptyValue: showEmptyValue,
                showTestData
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('get reports', error);
                if (errorMessage === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken(() =>
                        getSubReports(page, size, from, to, users, groups, templates, filters, itemId, showEmptyValue),
                    );
                }
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}
// function getSubReports(firstGroupName, firstGroupId, secondGroupName, dateFrom, dateTo, users, groups, templates) {
//     if(users[0] === 'default'){
//         users = [];
//     }
//     if(groups[0] === 'default'){
//         groups = [];
//     }
//     if(templates[0] === 'default'){
//         templates = [];
//     }
//     function getSecondValue(secondGroupName, users, groups, templates){
//         let result = [];
//         if(secondGroupName === 'user_id'){
//             result = users;
//         } else if(secondGroupName === 'group_id'){
//             result = groups;
//         } else if(secondGroupName === 'template_id'){
//             result = templates;
//         }
//
//         return result
//     }
//
//     return new Promise((resolve, reject) => {
//         AxiosRequest({
//             method: 'put',
//             url: `${urlConstant}/admin/reports/second`,
//             data: {
//                 'firstGroupName': firstGroupName,
//                 'firstGroupId': [firstGroupId],
//                 'secondGroupName': secondGroupName,
//                 'secondGroupId': getSecondValue(secondGroupName, users, groups, templates),
//                 'dateFrom': moment(dateFrom, 'DD/MM/YYYY').unix(),
//                 'dateTo': moment(dateTo, 'DD/MM/YYYY').unix(),
//             }
//         })
//             .then(response => {
//                 resolve(response);
//             })
//             .catch(error => {
//                 const errorMessage = groupErrorRequests('get reports', error);
//                 isRepair && console.error(errorMessage, error);
//                 reject(errorMessage);
//             })
//     })
// }

function getSubSubReports(from, to, users, groups, templates, filters, itemId, firstItemId, showEmptyValue, showTestData) {

    if (users[0] === 'default') {
        users = [];
    }
    if (groups[0] === 'default') {
        groups = [];
    }
    if (templates[0] === 'default') {
        templates = [];
    }
    let first = '';
    let second = '';
    let third = '';

    function getItemName(filters) {
        first = filters[0].ident;
        second = filters[1].ident;
        third = filters[2].ident;
    }

    getItemName(filters);

    function getItemValue(users, groups, templates, ident) {
        if (ident === 'user_id') {
            return users;
        } else if (ident === 'group_id') {
            return groups;
        } else if (ident === 'template_id') {
            return templates;
        }
    }

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/reports/third`,
            data: {
                dateFrom: moment(from, 'DD/MM/YYYY').unix(),
                dateTo: moment(to, 'DD/MM/YYYY').unix(),
                group1: {
                    name: first,
                    filters: [firstItemId],
                },
                group2: {
                    name: second,
                    filters: [itemId],
                },
                group3: {
                    name: third,
                    filters: getItemValue(users, groups, templates, third),
                },
                showEmptyValue: showEmptyValue,
                showTestData
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('get reports', error);
                if (errorMessage === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken(() =>
                        getSubSubReports(from, to, users, groups, templates, filters, itemId, firstItemId, showEmptyValue),
                    );
                }
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getReportsDetails(from, to, users, groups, templates, filters, firstItemId, secondItemId, thirdItemId, showEmptyValue) {

    let first = '';
    let second = '';
    let third = '';

    function getItemName(filters) {
        first = filters[0].ident;
        second = filters[1].ident;
        third = filters[2].ident;
    }

    getItemName(filters);

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/reports/details`,
            data: {
                dateFrom: moment(from, 'DD/MM/YYYY').unix(),
                dateTo: moment(to, 'DD/MM/YYYY').unix(),
                group1: {
                    name: first,
                    filters: [firstItemId],
                },
                group2: {
                    name: second,
                    filters: [secondItemId],
                },
                group3: {
                    name: third,
                    filters: [thirdItemId],
                },
                showEmptyValue: showEmptyValue,
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('get reports details', error);
                if (errorMessage === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken(() =>
                        getReportsDetails(
                            from,
                            to,
                            users,
                            groups,
                            templates,
                            filters,
                            firstItemId,
                            secondItemId,
                            thirdItemId,
                            showEmptyValue,
                        ),
                    );
                }
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}
// function getSubSubReports(firstGroupName, firstGroupId, secondGroupName, secondGroupId, thirdGroupName, dateFrom, dateTo,
//                           users, groups, templates) {
//     if(users[0] === 'default'){
//         users = [];
//     }
//     if(groups[0] === 'default'){
//         groups = [];
//     }
//     if(templates[0] === 'default'){
//         templates = [];
//     }
//     function getThirdValue(thirdGroupName, users, groups, templates){
//         let result = [];
//         if(thirdGroupName === 'user_id'){
//             result = users;
//         } else if(thirdGroupName === 'group_id'){
//             result = groups;
//         } else if(thirdGroupName === 'template_id'){
//             result = templates;
//         }
//
//         return result
//     }
//
//     return new Promise((resolve, reject) => {
//         AxiosRequest({
//             method: 'put',
//             url: `${urlConstant}/admin/reports/third`,
//             data: {
//                 firstGroupName,
//                 'firstGroupId': [firstGroupId],
//                 secondGroupName,
//                 'secondGroupId': [secondGroupId],
//                 thirdGroupName,
//                 'thirdGroupId': getThirdValue(thirdGroupName, users, groups, templates),
//                 'dateFrom': moment(dateFrom, 'DD/MM/YYYY').unix(),
//                 'dateTo': moment(dateTo, 'DD/MM/YYYY').unix()
//             }
//         })
//             .then(response => {
//                 resolve(response);
//             })
//             .catch(error => {
//                 const errorMessage = groupErrorRequests('get reports', error);
//                 isRepair && console.error(errorMessage, error);
//                 reject(errorMessage);
//             })
//     })
// }
