import { history } from '../Utils';

class Auth {
    static authenticateUser(token, role, refresh_token, id) {
        console.log('Auth token', token);
        console.log('Auth role', role);
        console.log('Auth reftoken', refresh_token);
        console.log('Auth id', id);
        if (role.authorities[0] === 'ROLE_ADMIN') {
            localStorage.setItem('is_admin', 'true');
            localStorage.setItem('user_role', role.authorities[0]);
        } else if (role.authorities[1] === 'ROLE_GROUP_ADMIN') {
            localStorage.setItem('user_role', role.authorities[1]);
        } else if (role.authorities[0] === 'ROLE_SEMINAR_USER') {
            localStorage.setItem('user_role', role.authorities[0]);
            localStorage.setItem('temp', 'true');
        } else if (role.authorities[1] === 'ROLE_SEMINAR_ADMIN' && role.authorities[2] === 'ROLE_GROUP_ADMIN') {
            localStorage.setItem('user_role', role.authorities[2]);
        } else {
            localStorage.setItem('user_role', role.authorities[0]);
        }
        localStorage.setItem('access_token', token);
        localStorage.setItem('refresh_token', refresh_token);
        // localStorage.setItem('user_role', role.authorities[0]);
        localStorage.setItem('user_id', id);
        // save all roles
        localStorage.setItem('user_roles', role.authorities);
    }

    static extendUserInfo(userName) {
        if (localStorage.getItem('username') === null) {
            localStorage.setItem('username', userName);
        }
    }
    static setTempUSer() {
        localStorage.setItem('temp', 'true');
    }

    static isUserTemp() {
        return localStorage.getItem('temp') !== null;
    }

    static isUserAuthenticated() {
        return localStorage.getItem('username') !== null;
    }

    static isUserAdmin() {
        return JSON.parse(localStorage.getItem('is_admin'));
    }

    static isUserGroupAdmin() {
        return this.getUserRole() === 'ROLE_GROUP_ADMIN';
    }

    static isUserSeminarAdmin() {
        return this.getUserRoles().findIndex(v => v === 'ROLE_SEMINAR_ADMIN') >= 0;
    }

    static deauthenticateUser() {
        localStorage.removeItem('is_admin');
        localStorage.removeItem('username');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user_role');
        localStorage.removeItem('user_roles');
        localStorage.removeItem('user_id');
        localStorage.removeItem('current_ip');
        localStorage.removeItem('temp');
        localStorage.removeItem('seminar_id');
        localStorage.removeItem('group_id');
        history.push('/sign-in');
    }

    static getUser() {
        return localStorage.getItem('username');
    }

    static getToken() {
        return localStorage.getItem('access_token');
    }

    static getRefreshToken() {
        return localStorage.getItem('refresh_token');
    }

    static setNewTokens(access_token, refresh_token) {
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('refresh_token', refresh_token);
    }

    static getUserRole() {
        return localStorage.getItem('user_role');
    }

    static getUserRoles() {
        return (localStorage.getItem('user_roles')).split(',');
    }

    static getUserId() {
        return Number(localStorage.getItem('user_id'));
    }

    static getSeminarId() {
        return localStorage.getItem('seminar_id');
    }
}

export default Auth;
