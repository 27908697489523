import { reportsService, userService } from '../Services';
import { reportsManagementConstants, errorRequest } from '../Constants';

export const reportsManagementActions = {
    getReportsFilters,
    changeUsersFilter,
    changeGroupsFilter,
    changeTemplatesFilter,
    getReports,
    getSubReports,
    setDateAction,
    setFiltersOrder,
    getSubSubReports,
    clearReports,
    changePage,
    changeSize,
    closeSubReports,
    getEmptyValue,
    setExpanded,
    openReportsDetailsModal,
    closeReportsDetailsModal,
    toggleIncludeTestData
};

function getReportsFilters() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        reportsService
            .getReportsFilters()
            .then(data => {
                dispatch(success(data.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: reportsManagementConstants.GET_REPORTS_FILTERS_REQUEST,
        };
    }
    function success(data) {
        return {
            type: reportsManagementConstants.GET_REPORTS_FILTERS_SUCCESS,
            payload: {
                users: data.users,
                groups: data.groups,
                templates: data.templates,
            },
        };
    }
    function failure() {
        return {
            type: reportsManagementConstants.GET_REPORTS_FILTERS_ERROR,
        };
    }
}

function changeUsersFilter(value) {
    return {
        type: reportsManagementConstants.CHANGE_USER_FILTER,
        payload: {
            value,
        },
    };
}

function changeGroupsFilter(value) {
    return {
        type: reportsManagementConstants.CHANGE_GROUP_FILTER,
        payload: {
            value,
        },
    };
}

function changeTemplatesFilter(value) {
    return {
        type: reportsManagementConstants.CHANGE_TEMPLATE_FILTER,
        payload: {
            value,
        },
    };
}

function getReports(page, size, from, to, users, groups, templates, filters, showEmptyValue, showTestData) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        reportsService
            .getReports(page, size, from, to, users, groups, templates, filters, showEmptyValue, showTestData)
            .then(data => {
                dispatch(success(data.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: reportsManagementConstants.GET_REPORTS_REQUEST,
        };
    }
    function success(data) {
        return {
            type: reportsManagementConstants.GET_REPORTS_SUCCESS,
            payload: {
                reports: data,
            },
        };
    }
    function failure() {
        return {
            type: reportsManagementConstants.GET_REPORTS_ERROR,
        };
    }
}

function getSubReports(page, size, from, to, users, groups, templates, filters, itemId, showEmptyValue, showTestData) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        reportsService
            .getSubReports(page, size, from, to, users, groups, templates, filters, itemId, showEmptyValue, showTestData)
            .then(data => {
                dispatch(success(data.data, itemId));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    console.log('Error', error);
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: reportsManagementConstants.GET_SUB_REPORTS_REQUEST,
        };
    }
    function success(data, itemId) {
        return {
            type: reportsManagementConstants.GET_SUB_REPORTS_SUCCESS,
            payload: {
                subReports: data,
                itemId,
            },
        };
    }
    function failure() {
        return {
            type: reportsManagementConstants.GET_SUB_REPORTS_ERROR,
        };
    }
}

function getSubSubReports(from, to, users, groups, templates, filters, itemId, firstItemId, showEmptyValue, showTestData) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        reportsService
            .getSubSubReports(from, to, users, groups, templates, filters, itemId, firstItemId, showEmptyValue, showTestData)
            .then(data => {
                dispatch(success(data.data, firstItemId, itemId));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    console.log('Error', error);
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: reportsManagementConstants.GET_SUB_SUB_REPORTS_REQUEST,
        };
    }
    function success(data, firstGroupId, secondGroupId) {
        return {
            type: reportsManagementConstants.GET_SUB_SUB_REPORTS_SUCCESS,
            payload: {
                subSubReports: data,
                firstGroupId,
                secondGroupId,
            },
        };
    }
    function failure() {
        return {
            type: reportsManagementConstants.GET_SUB_SUB_REPORTS_ERROR,
        };
    }
}
// function getSubSubReports(firstGroupName, firstGroupId, secondGroupName, secondGroupId, thirdGroupName, dateFrom, dateTo,
//                           users, groups, templates) {
//     return dispatch => {
//         main(dispatch);
//     };
//     function main(dispatch) {
//         dispatch(request());
//         reportsService.getSubSubReports(firstGroupName, firstGroupId, secondGroupName, secondGroupId, thirdGroupName, dateFrom,
//             dateTo, users, groups, templates)
//             .then(data => {
//                 dispatch(success(data.data, firstGroupId, secondGroupId));
//             })
//             .catch(error => {
//                 if (error === errorRequest.UNAUTHORIZED) {
//                     userService
//                         .refreshToken()
//                         .then(() => main(dispatch));
//                 } else {
//                     console.log('Error', error);
//                     dispatch(failure());
//                 }
//             });
//     }
//     function request() {
//         return {
//             type: reportsManagementConstants.GET_SUB_SUB_REPORTS_REQUEST,
//         }
//     }
//     function success(data, firstGroupId, secondGroupId) {
//         return {
//             type: reportsManagementConstants.GET_SUB_SUB_REPORTS_SUCCESS,
//             payload: {
//                 subSubReports: data,
//                 firstGroupId,
//                 secondGroupId
//             }
//         }
//     }
//     function failure() {
//         return {
//             type: reportsManagementConstants.GET_SUB_SUB_REPORTS_ERROR,
//         }
//     }
// }

function setDateAction(action, value, date) {
    return {
        type: reportsManagementConstants.SET_REPORTS_DATE_ACTION,
        payload: {
            action,
            value,
            date,
        },
    };
}

function setFiltersOrder(filters) {
    return {
        type: reportsManagementConstants.SET_FILTERS_ORDER,
        payload: {
            filters,
        },
    };
}

function clearReports() {
    return {
        type: reportsManagementConstants.CLEAR_REPORTS,
        payload: {},
    };
}

function changePage(value) {
    return {
        type: reportsManagementConstants.CHANGE_REPORTS_PAGE,
        payload: {
            value,
        },
    };
}

function changeSize(value) {
    return {
        type: reportsManagementConstants.CHANGE_REPORTS_SIZE,
        payload: {
            value,
        },
    };
}

function closeSubReports() {
    return {
        type: reportsManagementConstants.CLOSE_SUB_REPORTS,
        payload: {},
    };
}

function getEmptyValue() {
    return {
        type: reportsManagementConstants.GET_EMPTY_VALUE,
        payload: {},
    };
}

function setExpanded() {
    return {
        type: reportsManagementConstants.SET_EXPANDED,
        payload: {},
    };
}

function openReportsDetailsModal(from, to, users, groups, templates, filters, firstItemId, secondItemId, thirdItemId, showEmptyValue) {
    return dispatch => {
        open(dispatch);
        main(dispatch);
    };

    function main(dispatch) {
        dispatch(request());
        reportsService
            .getReportsDetails(from, to, users, groups, templates, filters, firstItemId, secondItemId, thirdItemId, showEmptyValue)
            .then(data => {
                dispatch(success(data.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    // dispatch(openStatusModal({
                    //     text: error,
                    //     status: false,
                    // }));
                }
            });
    }

    function request() {
        return {
            type: reportsManagementConstants.GET_REPORTS_DETAILS_REQUEST,
            payload: {},
        };
    }

    function success(data) {
        return {
            type: reportsManagementConstants.GET_REPORTS_DETAILS_SUCCESS,
            payload: {
                reportsDetails: data,
            },
        };
    }

    function failure() {
        return {
            type: reportsManagementConstants.GET_REPORTS_DETAILS_ERROR,
            payload: {},
        };
    }

    function open(dispatch) {
        dispatch({
            type: reportsManagementConstants.OPEN_REPORTS_DETAILS_MODAL,
            payload: {},
        });
    }
}

function closeReportsDetailsModal() {
    return {
        type: reportsManagementConstants.CLOSE_REPORTS_DETAILS_MODAL,
        payload: {},
    };
}

function toggleIncludeTestData() {
    return {
        type: reportsManagementConstants.TOGGLE_INCLUDE_TEST_DATA
    }
}